<template>
  <div>
    <!-- {{masterBiodata}} -->
    <b-card no-body class="text-center mt-1">
      <template #header>
        <b-container fluid class="w-100">
          <b-row>
            <b-col cols="8" class="text-left">
              <h4 class="mb-0">
                Daftar Permohonan
              </h4>
            </b-col>
            <b-col cols="4" class="text-right">
              <!-- <b-badge pill variant="success">Terverifikasi</b-badge> -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="showModalPermohonanSelection"
              >
                Ajukan Permohonan
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <b-card-body class="text-left mt-1 ml-2 mr-2 mb-2">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Nama:</label>
              <b-form-input
                v-model="selectedNamaLengkap"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @keyup.enter="onEnterClick"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Jenis Permohonan:</label>
              <v-select
                v-if="optionsJenisPermohonan"
                v-model="selectedJenisPermohonan"
                :options="optionsJenisPermohonan"
                label="nama"
                :clearable="true"
                @input="filterSelected"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Status Permohonan:</label>
              <v-select
                v-if="optionsStatusPermohonan"
                v-model="selectedStatusPermohonan"
                :options="optionsStatusPermohonan"
                label="nama"
                :clearable="true"
                @input="filterSelected"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="selectedPerPage"
              :options="optionsPerPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterSelected"
            />
            <label>entries</label>
          </b-col>
          <!-- <b-col md="4">
            <label>Sort</label>
            <v-select
              v-model="selectedPerPage"
              :options="optionsPerPage"
              :clearable="true"
              class="sort-by-selector d-inline-block mx-50"
            />
          </b-col> -->
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <b-table
              small
              :fields="fields"
              :items="localDaftarPermohonan"
              :busy="isBusy"
              responsive="sm"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle" />
                  <strong> Loading...</strong>
                </div>
              </template>
              <!-- A virtual column -->
              <template #cell(id)="data">
                {{ data.value }}
              </template>

              <template #cell(action)="data">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  size="sm"
                  @click="deleteAlamat(data.index)"
                >
                  <feather-icon icon="DeleteIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon rounded-circle ml-1"
                  size="sm"
                  @click="showModalEditAlamat(data.index)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </template>

              <template #cell(status_approval_id)="{ item }">
                <!-- {{ item.status_approval_id }} -->
                <b-badge v-if="item.is_draft === true" pill variant="info">
                  Draft
                </b-badge>
                <b-badge
                  v-if="
                    item.status_approval_id === '0' && item.is_draft === false
                  "
                  pill
                  variant="info"
                >
                  Pengajuan
                </b-badge>
                <b-badge
                  v-if="item.status_approval_id === '1'"
                  pill
                  variant="success"
                >
                  Approved
                </b-badge>
                <b-badge
                  v-if="item.status_approval_id === '2'"
                  pill
                  variant="warning"
                >
                  Perbaikan
                </b-badge>
                <b-badge
                  v-if="item.status_approval_id === '-1'"
                  pill
                  variant="primary"
                >
                  Tolak
                </b-badge>
              </template>
              <!-- A custom formatted column -->
              <template #cell(biodata)="{ item }">
                <b-link
                  v-if="item.is_draft"
                  :to="{
                    name: 'mandiri-permohonan-pengajuan',
                    params: {
                      jenis_permohonan_id: item.jenis_permohonan_id,
                      mode: 'pengajuan',
                      biodata_id: item.biodata_id,
                    },
                  }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  <strong>{{ item.biodata.nama_lengkap }}</strong>
                </b-link>
                <b-link
                  v-else
                  :to="{
                    name: 'mandiri-permohonan-detil',
                    params: { permohonan_id: item.id, mode: 'detil' },
                  }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  <strong>{{ item.biodata.nama_lengkap }}</strong>
                </b-link>
              </template>
              <template #cell(jenis_permohonan)="data">
                {{ data.value.nama }}
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table>
          </b-col>
          <b-col cols="12">
            <!-- <b-pagination-nav
              use-router
              :number-of-pages="10"
              class="mt-2"
              @link-gen="generateLink"
            /> -->
            <b-pagination-nav
              v-if="showPagination"
              v-model="selectedPageNum"
              :link-gen="linkGen"
              :number-of-pages="pagination.numberOfPages"
              use-router
            />
            <!-- <b-pagination
              v-if="showPagination"
              v-model="currentPage1"
              :per-page="itemPerPage"
              hide-goto-end-buttons
              :total-rows="totalRecords"
              @change="generateLink"
            /> -->
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- modal login-->
    <b-modal
      id="modal-permohonan-selection"
      ref="modal-permohonan-selection"
      cancel-variant="outline-secondary"
      hide-footer
      cancel-title="Close"
      title="Permohonan"
    >
      <div class="text-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="ajukanPermohonanMandiriDataUlang"
        >
          Permohonan Data Ulang
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BTable,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BBadge,
  BPaginationNav,
  BLink,
  VBModal,
  BModal,
  // BPagination,
  // BOverlay,
  BSpinner,
  BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getCacheableApi, api } from '@/hap-sia/setup'
// import VueRouter from 'vue-router'

// const { isNavigationFailure, NavigationFailureType } = VueRouter

export default {
  components: {
    BCard,
    BTable,
    BLink,
    BModal,
    // BCardHeader,
    BCardBody,
    // BCardText,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // flatPickr,
    // BOverlay,
    BSpinner,
    BContainer,
    BBadge,
    vSelect,
    BPaginationNav,
    // BPagination,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      localDaftarPermohonan: [],
      optionsPerPage: [1, 3, 10, 25, 50, 100],
      selectedPerPage: 10,
      fields: [
        // A virtual column that doesn't exist in items
        { key: 'id', label: 'ID' },
        // A column that needs custom formatting
        { key: 'biodata', label: 'Nama' },
        // A regular column
        { key: 'jenis_permohonan', label: 'Jenis' },
        { key: 'created_at', label: 'Tanggal Pengajuan' },
        // A regular column
        { key: 'status_approval_id', label: 'Status' },
        'Action',
      ],
      optionsJenisPermohonan: null,
      selectedJenisPermohonan: null,
      optionsStatusPermohonan: [
        {
          nama: 'Draft',
          query: 'is_draft=true',
          params: {
            is_draft: true,
          },
        },
        {
          nama: 'Pengajuan',
          query: 'is_draft=false&status_approval_id=0',
          params: {
            is_draft: false,
            status_approval_id: 0,
          },
        },
        {
          nama: 'Disetujui',
          query: 'is_draft=false&status_approval_id=1',
          params: {
            is_draft: false,
            status_approval_id: 1,
          },
        },
        {
          nama: 'Ditolak',
          query: 'is_draft=false&status_approval_id=-1',
          params: {
            is_draft: false,
            status_approval_id: -1,
          },
        },
      ],
      selectedStatusPermohonan: null,
      selectedSortBy: null,
      currentQuery: '',
      queryParams: {
        biodata_id: null,
        jenis_permohonan_id: null,
        status_approval_id: null,
        is_draft: null,
        sort_by: 'created_at|desc',
        limit: 10,
        offset: null,
        nama_lengkap: null,
        page: 0,
      },
      pagination: {
        numberOfPages: 1,
        totalRows: 0,
        rowsPerPage: 0,
      },
      selectedPageNum: 1,
      showPagination: true,
      items: [
        {
          id: '123',
          biodata: { nama_lengkap: 'Himawan Anindya Putra' },
          jenis_permohonan: { nama: 'Permohonan Data Ulang' },
          status_approval_id: '1',
          created_at: '2012-01-01',
        },
      ],
      selectedNamaLengkap: null,
      isBusy: false,
    }
  },
  // watch: {
  //   selectedPageNum() {
  //     console.log('selectedPerPage changed')
  //     if (this.selectedPageNum === 1) {
  //       // do nothing
  //       console.log('pertama')
  //     } else {
  //       console.log('Do something on PageNum changed')
  //     }
  //   },
  // },
  async mounted() {
    console.log('MOUNTED')
    const cacheApi = await getCacheableApi()
    try {
      const result = await cacheApi.get('master/jenis-permohonan')
      this.optionsJenisPermohonan = result.data
    } catch (error) {
      this.makeToast('danger', 'Error', error.response.data.message, true)
    }
    console.log('MOUNTED ISI URL QUERY')
    console.log(this.$route.query)

    // jika URL tidak memiliki query string
    if (Object.keys(this.$route.query).length === 0) {
      const queryParams = this.generateInitialQueryParam()
      const finalQuery = this.generateQueryFromBuilder(queryParams)
      this.currentQuery = finalQuery
      this.queryParams = queryParams

      try {
        this.isBusy = true
        const result = await api().get(`permohonan/search2${finalQuery}`)
        if (result.data.items.length > 0) {
          result.data.items.forEach(element => {
            this.localDaftarPermohonan.push(element)
          })
          // set total number of pages
          // console.log('TOTAL')
          // console.log(this.queryParams.limit)
          this.selectedPerPage = this.queryParams.limit
          this.pagination.totalRows = result.data.total
          this.pagination.numberOfPages = Math.ceil(
            this.pagination.totalRows / queryParams.limit,
          )
        }
        this.isBusy = false
      } catch (error) {
        alert(error)
        this.isBusy = false
      }
    } else {
      const finalQuery = this.generateQueryFromBuilder(this.$route.query)
      try {
        this.isBusy = true
        const result = await api().get(`permohonan/search2${finalQuery}`)
        if (result.data.items.length > 0) {
          result.data.items.forEach(element => {
            this.localDaftarPermohonan.push(element)
          })
          // set total number of pages
          // console.log('TOTAL')
          // console.log(this.queryParams.limit)
          this.selectedPerPage = this.$route.query.limit
          this.pagination.totalRows = result.data.total
          this.pagination.numberOfPages = Math.ceil(
            this.pagination.totalRows / this.$route.query.limit,
          )
          this.selectedPageNum = this.$route.query.page
          this.currentQuery = finalQuery
          this.queryParams = this.$route.query
          // remove offset and page
        }
        this.isBusy = false
      } catch (error) {
        alert(error)
        this.isBusy = false
      }
    }
  },
  async beforeRouteUpdate(to, from, next) {
    let queryParams
    if (Object.keys(to.query).length === 0) {
      queryParams = this.generateInitialQueryParam()
    } else {
      queryParams = to.query
    }
    const finalQuery = this.generateQueryFromBuilder(queryParams)
    this.currentQuery = finalQuery
    this.queryParams = queryParams
    try {
      this.isBusy = true
      this.localDaftarPermohonan.splice(0, this.localDaftarPermohonan.length)
      const result = await api().get(`permohonan/search2${this.currentQuery}`)
      if (result.data.items.length > 0) {
        result.data.items.forEach(element => {
          this.localDaftarPermohonan.push(element)
        })
        // set total number of pages
        // console.log('TOTAL')
        // console.log(this.queryParams.limit)
        this.pagination.totalRows = result.data.total
        this.pagination.numberOfPages = Math.ceil(
          this.pagination.totalRows / queryParams.limit,
        )
        this.selectedPageNum = queryParams.page
        // console.log(this.totalRecords)
        // this.itemPerPage = 1
        // this.totalNumberOfPages = result.data.total / this.queryParams.limit
      }
      this.isBusy = false
    } catch (error) {
      alert(error)
      this.isBusy = false
    }
    next()
  },
  methods: {
    newQueryBuilder() {
      return {
        biodata_id: null,
        jenis_permohonan_id: null,
        status_approval_id: null,
        is_draft: null,
        sort_by: 'created_at|desc',
        limit: 10,
        offset: null,
        nama_lengkap: null,
        page: 0,
      }
    },
    generateQueryFromBuilder(builder) {
      const queryParams = builder
      // disinilah kita merubah offset disesuaikan dengan param page
      const { page } = queryParams

      // hitung offset
      if (page) {
        if (page === '1') {
          queryParams.offset = 0
        } else {
          queryParams.offset = (page - 1) * queryParams.limit
        }
      }

      const keys = Object.keys(queryParams)
      let i = 0
      let currentQuery = ''
      keys.forEach(element => {
        if (
          queryParams[element] !== null &&
          queryParams[element] !== undefined
        ) {
          console.log('value')
          console.log(element)
          console.log(queryParams[element])
          if (i === 0) {
            currentQuery += '?'
            i += 1
          } else {
            currentQuery += '&'
          }
          currentQuery += `${element}=${queryParams[element]}`
        }
      })
      return currentQuery
    },
    linkGen(pageNum) {
      // replace terjadinya double parameter page
      if (this.queryParams.page !== pageNum) {
        this.currentQuery = this.currentQuery.replace(
          `&page=${this.queryParams.page}`,
          '',
        )
      }
      // hapus param offset dari query string
      // supaya lebih bagus tampilan query string
      // perhitungan offset dilakukan melalui param page.
      this.currentQuery = this.currentQuery.replace(
        `&offset=${this.queryParams.offset}`,
        '',
      )
      return `?${this.currentQuery}&page=${pageNum}`
      // return pageNum === 1 ? '?' : `?${this.currentQuery}&page=${pageNum}`
    },
    generateInitialQueryParam() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const queryParams = this.newQueryBuilder()
      if (
        userData.default_role === 'user' ||
        userData.default_role === 'member'
      ) {
        queryParams.biodata_id = userData.biodata_id
          ? userData.biodata_id
          : '-1'
      } else {
        queryParams.biodata_id = null
      }
      queryParams.limit = this.selectedPerPage
      queryParams.offset = 0
      queryParams.sort_by = 'created_at|desc'
      queryParams.page = 1
      if (this.selectedStatusPermohonan) {
        const keys = Object.keys(this.selectedStatusPermohonan.params)
        keys.forEach(item => {
          queryParams[item] = this.selectedStatusPermohonan.params[item]
        })
      }
      if (this.selectedJenisPermohonan) {
        queryParams.jenis_permohonan_id = this.selectedJenisPermohonan.id
      }
      queryParams.nama_lengkap = this.selectedNamaLengkap
        ? this.selectedNamaLengkap
        : null

      return queryParams
    },
    async filterSelected() {
      // this.showPagination = false

      // console.log('SELECTED')
      // console.log(this.selectedStatusPermohonan)
      // const queryParams = this.newQueryBuilder()
      // const keys = Object.keys(this.selectedStatusPermohonan.params)
      // keys.forEach(item => {
      //   queryParams[item] = this.selectedStatusPermohonan.params[item]
      // })
      // queryParams.limit = this.selectedPerPage
      // queryParams.offset = 0
      // queryParams.sort_by = 'created_at|desc'
      // queryParams.page = 1

      const queryParams = this.generateInitialQueryParam()
      const finalQuery = this.generateQueryFromBuilder(queryParams)

      this.currentQuery = finalQuery
      this.queryParams = queryParams

      console.log('Current Query when status permohonan berubah')
      console.log(this.queryParams)
      console.log(this.currentQuery)

      this.$router.push({
        path: `${this.currentQuery}`,
        // name: 'mandiri-list-permohonan',
        // query: { page: 9 },
      })
    },
    showModalPermohonanSelection() {
      // this.selectedIndex = null
      // this.selectedAlamat = this.createNewAlamat()

      this.$refs['modal-permohonan-selection'].show()
      // this.$refs['modal-alamat'].title = 'Mantap'
    },
    ajukanPermohonanMandiriDataUlang() {
      this.$router.push({
        name: 'mandiri-permohonan-pengajuan',
        params: {
          mode: 'pengajuan',
          jenis_permohonan_id: '3',
          biodata_id: 0,
        },
      })
    },
    onEnterClick() {
      this.filterSelected()
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 3000,
        noAutoHide,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.sort-by-selector {
  width: 120px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
